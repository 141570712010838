<template>
  <v-container fluid>
    <v-card class="mx-auto">
      <v-toolbar color="primary" dark flat>
        <v-toolbar-title>{{ $t("__storeplan") }}</v-toolbar-title>
      </v-toolbar>
      <v-card-title v-if="storePlan">
        <table class="data-table">
          <tr>
            <th colspan="2">
              {{ $t("__storeplan_current") }}
              <v-chip
                class="mr-2"
                small
                v-if="checkExpired"
                :color="checkExpired"
              >
                {{
                  checkExpired === "error" || checkExpired === "yellow"
                    ? $t("__storeplan_near_expired")
                    : $t("__storeplan_expired")
                }}
              </v-chip>
              <v-chip small color="error" v-if="plan['is_suspended']">
                {{ $t("__storeplan_suspended") }}
              </v-chip>
            </th>
          </tr>
          <tr>
            <th>{{ $t("__storeplan_name") }}</th>
            <td v-if="!isMobile">
              {{ plan.name ? plan.name : $t("__nonactivated") }}
              <v-btn
                class="ml-2"
                outlined
                small
                color="primary"
                @click="toPlanDetail(plan)"
                >{{ $t("__storeplan_detail") }}</v-btn
              >
            </td>
          </tr>
          <tr>
            <td v-if="isMobile" class="data-table__subrow_td">
              {{ plan.name ? plan.name : $t("__nonactivated") }}
              <v-btn
                class="ml-2"
                outlined
                small
                color="primary"
                @click="toPlanDetail(plan)"
                >{{ $t("__storeplan_detail") }}</v-btn
              >
            </td>
          </tr>
          <template v-for="(item, i) in planItems">
            <tr :key="`data-${i}`" v-if="plan.name">
              <th>{{ item.text }}</th>
              <td v-if="!isMobile">
                <template>
                  {{ plan[item.key] }}
                </template>
              </td>
            </tr>
            <tr v-if="isMobile && plan.name" :key="`data-${i}-1`">
              <td class="data-table__subrow_td">
                <template>
                  {{ plan[item.key] }}
                </template>
              </td>
            </tr>
          </template>
        </table>
      </v-card-title>
      <v-card-text>
        <div class="text-h6 font-weight-bold">
          {{ $t("__storeplan_histories") }}
        </div>
        <v-data-table
          :headers="headers"
          :items="histories"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
        >
          <template v-slot:[`item.planID`]="{ item }">
            <v-btn text small color="primary" @click="toPlanDetail(item)">
              {{ item.plan_id }}
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <v-dialog v-model="planDetailDialog" max-width="600">
      <PlanDetail :data="planDetailDialogData" @close="detailClose" />
    </v-dialog>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import { format, subMonths } from "date-fns";
import PlanDetail from "@/components/PlanAndBills/PlanDetail";

export default {
  name: "Plan",
  components: {
    PlanDetail
  },
  computed: {
    ...mapGetters({
      isMobile: "isMobile",
      userID: "user/uid",
      planID: "plan/planID",
      storePlan: "plan/data",
      planHistories: "plan/histories"
    }),
    plan() {
      let plan = {};
      plan = { ...this.storePlan };
      plan["start_time"] = this.formatTime(this.storePlan["start_time"]);
      plan["end_time"] = this.formatTime(this.storePlan["end_time"]);
      plan["update_time"] = this.formatTime(this.storePlan["update_time"]);
      return plan;
    },
    checkExpired() {
      let endTime = this.storePlan["end_time"];
      let today = format(new Date(), "T");
      let endTimeStamp = format(new Date(endTime), "T");
      let twoMonthAgo = subMonths(new Date(endTime), 2);
      let twoMonthAgoTimeStamp = format(twoMonthAgo, "T");
      let oneMonthAgo = subMonths(new Date(endTime), 1);
      let oneMonthAgoTimeStamp = format(oneMonthAgo, "T");
      if (today >= oneMonthAgoTimeStamp) return "error";
      else if (today >= endTimeStamp) return "grey";
      else if (today >= twoMonthAgoTimeStamp) return "yellow";
      else return null;
    }
  },
  data() {
    return {
      planItems: [
        {
          text: this.$t("__storeplan_start_time"),
          key: "start_time"
        },
        {
          text: this.$t("__storeplan_end_time"),
          key: "end_time"
        },
        {
          text: this.$t("__storeplan_update_time"),
          key: "update_time"
        }
      ],
      headers: [
        {
          text: this.$t("__storeplan_ID"),
          value: "planID",
          align: "start"
        },
        {
          text: this.$t("__storeplan_start_time"),
          value: "startTime",
          align: "start"
        },
        {
          text: this.$t("__storeplan_stop_time"),
          value: "stopTime",
          align: "start"
        },
        {
          text: this.$t("__storeplan_update_time"),
          value: "updateTime",
          align: "start"
        }
      ],
      sortBy: "updateTime",
      sortDesc: true,
      histories: [],
      planDetailDialog: false,
      planDetailDialogData: null
    };
  },
  methods: {
    updateHistories() {
      for (const history of this.planHistories) {
        this.histories.push({
          plan_id: history["plan_id"],
          startTime: this.formatTime(history["start_time"]),
          stopTime: this.formatTime(history["stop_time"]),
          updateTime: this.formatTime(history["update_time"])
        });
      }
    },
    loadUserPlan() {
      this.$store.dispatch("setIsLoading", true);
      return this.$store
        .dispatch("plan/fetchUserPlan", this.userID)
        .then(() => {
          this.$store.dispatch("setIsLoading", false);
        })
        .catch(err => {
          console.log(err);
          this.$store.dispatch("setIsLoading", false);
        });
    },
    toPlanDetail(item) {
      this.planDetailDialog = true;
      this.getPlan(item.plan_id);
    },
    detailClose() {
      this.planDetailDialog = false;
      this.planDetailDialogData = null;
    },
    getPlan(planID) {
      this.$store.dispatch("setIsLoading", true);
      return this.$store
        .dispatch("plan/getPlan", planID)
        .then(res => {
          this.planDetailDialogData = res.plan;
          this.$store.dispatch("setIsLoading", false);
        })
        .catch(err => {
          this.$store.dispatch("setIsLoading", false);

          console.log(err);
        });
    },
    formatTime(time) {
      if (time === "0001-01-01T00:00:00Z") return "-";
      return format(new Date(time), "yyyy/MM/dd");
    }
  },
  created() {
    this.loadUserPlan();
  },
  watch: {
    planHistories: {
      deep: true,
      handler() {
        this.updateHistories();
      }
    }
  }
};
</script>
<style lang="scss">
.data-table {
  width: 100%;
  border-collapse: collapse;
  margin-block: 1em;

  th,
  td {
    border: 1px solid silver;
    padding: 8px;
  }
  th {
    width: 1%;
    white-space: nowrap;
    text-align: left;
    color: black;
  }
  td {
    color: black;
  }
  &__subrow_td {
    padding-left: 24px !important;
  }
}
</style>
