<template>
  <v-card v-if="data">
    <v-card-title>
      {{ $t("__storeplan_detail") }}
    </v-card-title>
    <v-card-text>
      <table class="data-table">
        <tr>
          <th>{{ $t("__storeplan_name") }}</th>
          <td>
            {{ data.name }}
          </td>
        </tr>
        <tr v-if="data['one_time_fee']">
          <th>{{ $t("__storeplan_one_time_fee") }}</th>
          <td>
            {{ data["one_time_fee"] | currency }}
          </td>
        </tr>
        <tr v-if="data['regular_fee']">
          <th>{{ $t("__storeplan_regular_fee") }}</th>
          <td>
            {{ data["regular_fee"] | currency }}
            {{
              "/" + $t(`__storeplan_regular_fee_${data["regular_fee_cycle"]}`)
            }}
          </td>
        </tr>
        <tr>
          <th :rowspan="transactionFees.length + 1">
            {{ $t("__storeplan_transaction_fee") }}
          </th>
        </tr>
        <tr
          v-for="transactionFee of transactionFees"
          :key="transactionFee.amount"
        >
          <td>
            >
            {{ transactionFee.amount | currency }} :
            {{ transactionFee.percentage + "%" }}
          </td>
        </tr>
        <tr>
          <th>{{ $t("__storeplan_contract_duration") }}</th>
          <td>
            {{
              data["contract_duration"] === 0
                ? "-"
                : data["contract_duration"] +
                  $t(
                    `__storeplan_contract_duration_${data["contract_duration_unit"]}`
                  )
            }}
          </td>
        </tr>
        <tr>
          <th>{{ $t("__storeplan_publish_item_qty") }}</th>
          <td>
            {{
              data["allow_published_item_quantity"] === -1
                ? $t("__storeplan_unlimited")
                : data["allow_published_item_quantity"]
            }}
          </td>
        </tr>
        <tr>
          <th>{{ $t("__storeplan_message_qty_title") }}</th>
          <td>
            <span
              v-if="
                $i18n.locale === 'zh-hant' &&
                  data['allow_push_store_message_quantity'] !== -1
              "
            >
              {{
                $t(
                  `__storeplan_message_interval_${data["allow_push_store_message_interval"]}`
                )
              }}
            </span>
            {{
              data["allow_push_store_message_quantity"] === -1
                ? $t("__storeplan_unlimited")
                : data["allow_push_store_message_quantity"]
            }}
            <span v-if="data['allow_push_store_message_quantity'] !== -1">{{
              $t("__storeplan_message_qty")
            }}</span>
            <span
              v-if="
                $i18n.locale === 'en' &&
                  data['allow_push_store_message_quantity'] !== -1
              "
            >
              {{
                $t(
                  `__storeplan_message_interval_${data["allow_push_store_message_interval"]}`
                )
              }}
            </span>
          </td>
        </tr>
      </table>

      <div class="text-h7 font-weight-bold black--text pl-2">
        {{ $t("__storeplan_platform") }}
      </div>
      <div class="d-flex flex-wrap pl-2">
        <div
          v-for="feature of platFormFeature"
          :key="feature.title"
          class="mr-2"
        >
          <v-icon color="success" v-if="feature.allow">
            mdi-check-bold
          </v-icon>
          <v-icon color="error" v-else>
            mdi-close-thick
          </v-icon>
          <span class="text-subtitle-1">
            {{ $t(`__storeplan_platform_${feature.title}`) }}
          </span>
        </div>
      </div>
      <div class="text-h7 font-weight-bold black--text pl-2 mt-3">
        {{ $t("__storeplan_payment") }}
      </div>
      <div class="d-flex flex-wrap pl-2">
        <div
          v-for="feature of paymentFeature"
          :key="feature.title"
          class="mr-2"
        >
          <v-icon color="success" v-if="feature.allow">
            mdi-check-bold
          </v-icon>
          <v-icon color="error" v-else>
            mdi-close-thick
          </v-icon>
          <span class="text-subtitle-1">
            {{ $t(`__storeplan_payment_${feature.title}`) }}
          </span>
        </div>
      </div>
      <div class="text-h7 font-weight-bold black--text pl-2 mt-3">
        {{ $t("__storeplan_shipping_channel") }}
      </div>
      <div class="d-flex flex-wrap pl-2">
        <div
          v-for="feature of shippingChannelFeature"
          :key="feature.title"
          class="mr-2"
        >
          <v-icon color="success" v-if="feature.allow">
            mdi-check-bold
          </v-icon>
          <v-icon color="error" v-else>
            mdi-close-thick
          </v-icon>
          <span class="text-subtitle-1">
            {{ $t(`__storeplan_shipping_channel_${feature.title}`) }}
          </span>
        </div>
      </div>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn text color="primary" @click="close">
        {{ $t("__close") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
export default {
  name: "PlanDetail",
  props: {
    data: {
      type: Object
    }
  },
  computed: {
    transactionFees() {
      let fee = [];
      let amounts = Object.keys(this.data["transaction_fees_percentage"]);
      for (const amount of amounts) {
        fee.push({
          amount: amount,
          percentage: this.data["transaction_fees_percentage"][amount]
        });
      }
      return fee;
    },
    platFormFeature() {
      let feature = [
        {
          title: "telegramBotChat",
          allow: this.data["allow_telegram_bot_chat"]
        },
        {
          title: "onePageWeb",
          allow: this.data["allow_one_page_web"]
        }
      ];

      return feature;
    },
    paymentFeature() {
      let feature = [
        {
          title: "cash",
          allow: this.data["allow_payment_cash"]
        },
        {
          title: "cashOnDelivery",
          allow: this.data["allow_payment_cash_on_delivery"]
        },
        {
          title: "atm",
          allow: this.data["allow_payment_atm"]
        },
        {
          title: "tappay",
          allow: this.data["allow_payment_tappay"]
        },
        {
          title: "ecpay",
          allow: this.data["allow_payment_ecpay"]
        }
      ];
      return feature;
    },
    shippingChannelFeature() {
      let feature = [
        {
          title: "pickup",
          allow: this.data["allow_shipping_pickup"]
        },
        {
          title: "logistics",
          allow: this.data["allow_shipping_logistics"]
        }
      ];
      return feature;
    }
  },
  methods: {
    close() {
      this.$emit("close");
    }
  }
};
</script>
